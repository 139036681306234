:root {
  --primaryColor: #0df1eb;
  --linear-gradient: linear-gradient(89deg, #20966e, var(--primaryColor));
}
h1, h2, h3 ,h4, h5, h6{
  letter-spacing: 1px !important;
}
p{
  letter-spacing: 1px !important;
}
ul li{
  letter-spacing: 1px !important;
}
.accordion-body{
  font-size: 18px !important;
}
.accordion-header{
  font-size: 18px !important;
}
.global-btn {
  background: transparent;
  border: none;
  border-radius: 8px;
  padding: 8px 20px;
  box-shadow: rgba(99, 227, 221, 0.2) 0px 7px 29px 0px;
}
.register-btn {
  background: var(--linear-gradient) !important;
  border: none;
  border-radius: 30px;
  padding: 8px 20px;
  box-shadow: rgba(99, 227, 221, 0.2) 0px 7px 29px 0px;
}

body {
  /* font-family: "Lato", sans-serif; */
  scroll-behavior: smooth !important;
  /* font-family: "Alegreya Sans SC", sans-serif; */
  font-family: "Alegreya Sans", sans-serif;
}

.hero h1 {
  font-size: 60px;
}
.hero p {
  font-size: 16px;
}
.typing-text {
  font-size: 18px !important;
}
.btn-close {
  background-color: var(--primaryColor) !important;
}

@media only screen and (max-width: 768px) {
  .hero h1 {
    font-size: 30px;
  }
  .typing-text {
    font-size: 16px !important;
  }
  .heading-text {
    width: 100% !important;
  }
}

.nav-link {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 14px;
  color: white !important;
}

.nav-link .underline {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primaryColor);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  border-radius: 60px;
}

.nav-link:hover .underline,
.nav-link.active .underline {
  transform: scaleX(1);
}

.accordion-button:not(.collapsed) {
  color: var(--primaryColor) !important;
  background-color: black;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
  font-weight: 500;
  text-shadow: "2px 2px 4px rgba(0, 0, 0, 0.7)";
  border-radius: 30px !important;
}
.accordion-item .collapsed {
  border-radius: 30px !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  margin-right: 0.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
  background-color: white;
}
.accordion-button:not(.collapsed)::after {
  margin-right: 0.5em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2369f0ea' class='bi bi-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath d='M5 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E") !important;
  background-color: transparent;
}

.feature-card {
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: scale(1.02) rotate(1deg);
}

@keyframes moveLeftToRight {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 10px);
  }
  100% {
    left: 0;
  }
}
.navbar-nav a{
  font-size: 16px !important;
}
.hero-top {
  position: relative;
  display: flex;
  
  justify-content: center;
  height: 93vh;
  overflow: hidden;
  /* background-image: url("./assets/linesfirst.png") !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f2f6f6;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 400px; */
  overflow: hidden;
  /* background-image: url("./assets/linesfirst.png") !important; */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f2f6f6;
  border-radius: 20px;
}

.hero-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.hero-section .container {
  z-index: 3;
}

.typing-text {
  z-index: 3;
}

.global-btn {
  z-index: 3;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(106, 242, 236, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: #000000;
  color: white;
  transform: rotateY(180deg);
}

.glowing-border {
  position: relative;
  border: 2px solid #0df1eb !important;
  border-radius: 8px;
  box-shadow: 0 0 20px #0df1eb !important;
  animation: glowing-border 2s linear infinite !important;
}

.glowing-border:hover {
  border: 2px solid #0df1eb !important;
}
.glowing-border:focus {
  border: 2px solid #0df1eb !important;
}
@keyframes glowing-border {
  0% {
    box-shadow: 0 0 10px #0df1eb;
  }
  50% {
    box-shadow: 0 0 20px #0df1eb;
  }
  100% {
    box-shadow: 0 0 10px #0df1eb;
  }
}

.outerhow h1 {
  color: #000000;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
}
.pricing_table {
  color: #fff;
  margin: 10px 0;
  background: #f1f1f1;
  text-align: center;
  transition: all 0.3s linear 0s;
  border-radius: 25px;
}
.pricing_table:hover {
  background: var(--primaryColor);
}
.pricing_table.active,
.pricing_table .pricing_table_plan .btn-info.active {
  background: var(--primaryColor);
}
.pricing_table:hover .pricing_table_plan .btn-info {
  background: var(--primaryColor);
}
.pricing_table_header {
  padding: 40px;
}
.pricing_table_header > i {
  display: block;
  font-size: 40px;
}
.pricing_table_header .title {
  display: block;
  text-transform: capitalize;
}
.pricing_table_header .price-plan {
  display: block;
}
.pricing_table_header .price-plan i {
  font-size: 16px;
}
.pricing_table_header .price {
  font-size: 40px;
}
.pricing_table_header .monthly_plan {
  font-size: 20px;
}
.pricing_table .pricing_table_plan {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  padding: 40px 20px;
  background: #000000;
}
.pricing_table .pricing_table_plan ul {
  padding: 0;
}
.pricing_table .pricing_table_plan ul li {
  list-style: none;
  margin: 15px 0;
}

.transform-hover {
  transition: transform 0.3s ease-in-out;
}

.transform-hover:hover {
  transform: scale(1.05);
}

.heading-text {
  width: 70%;
}
.service-card {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}
.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(105, 240, 234, 0.2);
}

.contact-icons {
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  border-radius: 50px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-menu {
  background-color: black !important;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
  padding: 0 !important;
  border-radius: 10px;
  overflow: hidden;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primaryColor) !important;
  color: black !important;
}

.breadcrumb-item a {
  color: gray !important;
  text-decoration: none !important;
}
.breadcrumb .active {
  color: black !important;
}
.linesbg {
  background-image: url("./assets/linesbg.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.pentagonbg {
  background-image: url("./assets/sidepentagon.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.keyfeaturesbg {
  background-image: url("./assets/keyfeaturesbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}


.rotateicon {
  transition: transform 0.3s ease;
}
.card:hover .rotateicon {
  transform: rotate(360deg);
}
.flipicon {
  transition: transform 0.3s ease;
}
.card:hover .flipicon {
  transform: rotateY(360deg);
}
.flipxicon {
  transition: transform 0.3s ease;
}
.card:hover .flipxicon {
  transform: rotateX(360deg);
}